import { useEffect, useRef, useState } from "react";
import "./App.css";

function AutoWrite({
  text = "",
  speed,
  delay,
  random,
  start,
  element,
  setDone,
  setCursor,
  deleteText = false,
}) {
  let i = 0;
  let started = false;
  let finished = false;
  if (!deleteText) {
    element.innerHTML = "";
  }
  function typeWriter() {
    if (!start) {
      setCursor(true);
      return setTimeout(typeWriter, speed);
    }
    if (!started) {
      started = true;
      return setTimeout(typeWriter, delay);
    }
    if (deleteText && element.innerHTML.length > 0) {
      element.innerHTML = element.innerHTML.slice(0, -1);
      i--;
      const nextDelay = speed + Math.round(random * Math.random());
      setCursor(nextDelay > 300);
      return setTimeout(typeWriter, nextDelay);
    }
    else if (i < text.length) {
      const char = text.charAt(i);
      element.innerHTML += char;
      i++;
      const factor = char == " " ? 5 : 1;
      const nextDelay = speed + Math.round(random * Math.random() * factor);
      setCursor(nextDelay > 300);
      return setTimeout(typeWriter, nextDelay);
    }
    if (!finished) {
      finished = true;
      setCursor(true);
      return setTimeout(typeWriter, delay);
    }
    setDone(true);
  }

  return typeWriter();
}

function Cursor({ start, end, blink, speed = 300 }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (start && !end) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [start, end]);

  useEffect(() => {
    if (!start || end || !blink) {
      setShow(start && !end);
      return;
    }

    let show_ = false;
    const interval = setInterval(() => {
      setShow(!show_);
      show_ = !show_;
    }, speed);

    return () => {
      clearInterval(interval);
    };
  }, [start, end, blink, speed]);

  return (
    show && (
      <span className="absolute left-full w-4 h-full border-l-2 border-gray-500"></span>
    )
  );
}

function App() {
  const [titleDone, setTitleDone] = useState(false);
  const [subTitleDone1, setSubTitleDone1] = useState(false);
  const [subTitleDone2, setSubTitleDone2] = useState(false);
  const [subTitleDone3, setSubTitleDone3] = useState(false);
  const [subTitleDone4, setSubTitleDone4] = useState(false);
  const [subTitleDone5, setSubTitleDone5] = useState(false);
  const [emailDone1, setEmailDone1] = useState(false);
  const [emailDone2, setEmailDone2] = useState(false);
  const [emailDone3, setEmailDone3] = useState(false);
  const [showCursor, setShowCursor] = useState(false);
  const titleElement = useRef();
  const subTitleElement = useRef();
  const emailElement = useRef();

  useEffect(() => {
    setTitleDone(false);
    setSubTitleDone1(false);
    setSubTitleDone2(false);
    setSubTitleDone3(false);
    setSubTitleDone4(false);
    setSubTitleDone5(false);
    setEmailDone1(false);
    setEmailDone2(false);
    setEmailDone3(false);

    const titleHandler = AutoWrite({
      text: "Hello world",
      speed: 60,
      delay: 500,
      random: 100,
      start: true,
      element: titleElement.current,
      setDone: setTitleDone,
      setCursor: setShowCursor,
    });

    return () => {
      clearTimeout(titleHandler);
    };
  }, []);

  useEffect(() => {
    const subTitleHandler = AutoWrite({
      text: "Website dev",
      speed: 30,
      delay: 150,
      random: 70,
      start: titleDone,
      element: subTitleElement.current,
      setDone: setSubTitleDone1,
      setCursor: setShowCursor,
    });

    return () => {
      clearTimeout(subTitleHandler);
    };
  }, [titleDone]);

  // Delete text
  useEffect(() => {
    const subTitleHandler = AutoWrite({
      speed: 50,
      delay: 100,
      random: 50,
      start: subTitleDone1,
      element: subTitleElement.current,
      setDone: setSubTitleDone2,
      setCursor: setShowCursor,
      deleteText: true,
    });

    return () => {
      clearTimeout(subTitleHandler);
    };
  }, [subTitleDone1]);

  useEffect(() => {
    const subTitleHandler = AutoWrite({
      text: "Game develop",
      speed: 30,
      delay: 300,
      random: 70,
      start: subTitleDone2,
      element: subTitleElement.current,
      setDone: setSubTitleDone3,
      setCursor: setShowCursor,
    });

    return () => {
      clearTimeout(subTitleHandler);
    };
  }, [subTitleDone2]);

  // Delete text
  useEffect(() => {
    const subTitleHandler = AutoWrite({
      speed: 50,
      delay: 100,
      random: 50,
      start: subTitleDone3,
      element: subTitleElement.current,
      setDone: setSubTitleDone4,
      setCursor: setShowCursor,
      deleteText: true,
    });

    return () => {
      clearTimeout(subTitleHandler);
    };
  }, [subTitleDone3]);

  useEffect(() => {
    const subTitleHandler = AutoWrite({
      text: "Software development.",
      speed: 50,
      delay: 200,
      random: 50,
      start: subTitleDone4,
      element: subTitleElement.current,
      setDone: setSubTitleDone5,
      setCursor: setShowCursor,
    });

    return () => {
      clearTimeout(subTitleHandler);
    };
  }, [subTitleDone4]);

  useEffect(() => {
    const emailHandler = AutoWrite({
      text: "Get in touch",
      speed: 50,
      delay: 200,
      random: 50,
      start: subTitleDone5,
      element: emailElement.current,
      setDone: setEmailDone1,
      setCursor: setShowCursor,
    });

    return () => {
      clearTimeout(emailHandler);
    };
  }, [subTitleDone5]);

  // Delete text
  useEffect(() => {
    const subTitleHandler = AutoWrite({
      speed: 50,
      delay: 100,
      random: 50,
      start: emailDone1,
      element: emailElement.current,
      setDone: setEmailDone2,
      setCursor: setShowCursor,
      deleteText: true,
    });

    return () => {
      clearTimeout(subTitleHandler);
    };
  }, [emailDone1]);

  useEffect(() => {
    const emailHandler = AutoWrite({
      text: "info@helloworld.com.uy",
      speed: 50,
      delay: 1000,
      random: 50,
      start: emailDone2,
      element: emailElement.current,
      setDone: setEmailDone3,
      setCursor: setShowCursor,
    });

    return () => {
      clearTimeout(emailHandler);
    };
  }, [emailDone2]);

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-Title relative">
          <span ref={titleElement}>Hello world</span>
          <Cursor start={true} end={titleDone} blink={showCursor} />
        </h1>
        <h2 className="App-subTitle relative">
          <span ref={subTitleElement}>Software development</span>
          <Cursor start={titleDone} end={subTitleDone3} blink={showCursor} />
        </h2>
        <a
          className={`App-Email relative ${emailDone3 && "animate-pulse"}`}
          href="mailto:info@helloworld.com.uy"
        >
          <span ref={emailElement}>info@helloworld.com.uy</span>
          <Cursor
            start={subTitleDone3}
            end={false}
            blink={emailDone2}
            speed={500}
          />
        </a>
      </header>
    </div>
  );
}

export default App;
